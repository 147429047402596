
export default {
	name: 'mouthStatistics',
	data() {
		return {
			yaerAll: '',
			list: {},
			currentPage: 1,
			data2: {},
			searchers: false,
			yeas: '',
			craName: ''
		}
	},

	created() {
		var date = new Date;
		this.yeas = date.getFullYear();
		this.token = this.getCookie('token')
		this.getmouthStatistics(10, 1, '', '')
	},
	methods: {
		getmouthStatistics(number, page, craName, isAll) {
			var _this = this;
			_this.$http.post(_this.craUrl + '/JCOrderByCra', {
				number: '',
				page: page,
				craName: craName,
				isAll: isAll
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					_this.data2 = res.data.data.data;
				} else if (res.data.code == 401) {
					_this.$router.push('/')
				}
			})
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage;
			if (this.searchers) {
				this.searchBtn()
			} else {
				this.getmouthStatistics(10, currentPage, '')
			}
		},
		// 搜索
		searchBtn() {
			var _this = this;
			this.searchers = true;
			this.getmouthStatistics(10, this.currentPage, this.craName)
		},
		// 返回
		toCrcMena() {
			this.$router.push('/CraBidding')
		},
		//导出
		export2Excel() {
			let _this = this;
			if (_this.data2) {
				_this.$http.post(_this.craUrl + '/JCOrderByCra', {
					number: '',
					page: '',
					craName: '',
					isAll: 1
				}, {
					headers: {
						"token": _this.token
					}
				}).then(function (res) {
					var date = new Date
					var yeas = date.getFullYear()
					if (res.data.code == 200) {
						_this.list = res.data.data.data;
						require.ensure([], () => {
							const {
								export_json_to_excel
							} = require('../../vendor/Export2Excel');
							const tHeader = ['姓名', '2019Q1', '2019Q2', '2019Q3', '2019Q4', '上年度平均个人监查工时'];
							const filterVal = ['craName', 'firstQuarterJc', 'twoQuarterJc', 'threeQuarterJc', 'forQuarterJc', 'avgLastYear'];
							const data = _this.formatJson(filterVal, _this.list);
							export_json_to_excel(tHeader, data, '公司' + yeas + '季度人员监查工时统计排名');
						})
					}
				})
			} else {
				this.$message.error('操作异常');
			}
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	}
}
